import axios from "axios";
import {API, BEARER} from "./constants";
import {clearUser} from "../../state/userStateAtom";
import {getToken, setToken} from "./token";
import {appContext} from "../../state/appContext";

export const getUserInfo = async (token) => {
    return axios.get(`${API}/users/me`, {
        headers: {Authorization: `${BEARER} ${token}`},
    }).then((response) => {
        return response.data;
    }).catch((e) => {
        clearUser(appContext);
        console.log(e);
    });
}

export const registerUser = async (formData, onClose) => {
    return axios.post(`${API}/auth/local/register`, formData).then((response) => {
        //set the token
        setToken(response.data.jwt);
        onClose();
        return response.data.user;
    });
}

export const loginUser = async (formData, onClose) => {
    await axios.post(`${API}/auth/local`, formData).then((response) => {
        //set the token
        setToken(response.data.jwt);
        onClose();
        return response.data.user;
    });
}

export const updateUser = async (formData, userId) => {
    const authToken = getToken();
    await axios.put(`${API}/users/${userId}`, formData, {
        headers: {Authorization: `${BEARER} ${authToken}`},
    }).then((response) => {
        return response.data.user;
    });
}

export const forgotPassword = async (email) => {
    const authToken = getToken();
    const data = {email: email};
    await axios.post(`${API}/auth/forgot-password`, data, {
        headers: {Authorization: `${BEARER} ${authToken}`},
    }).then((response) => {
        return response.data.user;
    });
}

export const resetPassword = async (data) => {
    const authToken = getToken();
    await axios.post(`${API}/auth/reset-password`, data, {
        headers: {Authorization: `${BEARER} ${authToken}`},
    }).then((response) => {
        return response.data.user;
    });
}
