import {action, atom} from "@reatom/core";
import {getUserInfo} from "../api/auth/user";
import {getToken} from "../api/auth/token";

type User = {
    id: number,
    username: string,
    email: string,
    provider: string,
    confirmed: boolean,
    blocked: boolean
    createdAt: string,
    updatedAt: string,
    uuid: string,
}

export const userStateAtom = atom<User>({} as User);

export const getUser = action(async (ctx) => {
    const authToken = getToken();
    const user = await ctx.schedule(() =>
        getUserInfo(authToken),
    )
    ctx.get(() => {
        userStateAtom(ctx, user);
    })
}, "getUser");

export const clearUser = action(async (ctx) => {
    ctx.get(() => {
        userStateAtom(ctx, {} as User);
    })
}, "clearUser")