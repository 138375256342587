import axios from "axios";
import {API, BEARER} from "../auth/constants";
import {getToken} from "../auth/token";

export const createOperation = async (formData) => {
    const token = getToken();
    return axios.post(`${API}/operations`, JSON.stringify({data: formData}), {
        headers: {Authorization: `${BEARER} ${token}`, "Content-Type": "application/json"},
    }).then((response) => {
        return response.data.data.id;
    }).catch((e) => {
        console.log(e);
    })
}

export const getOperations = async () => {
    const token = getToken();
    return axios.get(`${API}/get-subscription`, {
        headers: {Authorization: `${BEARER} ${token}`},
    }).then((response) => {
        return response.data;
    }).catch((e) => {
        console.log(e);
    });
}

export const createAutoPayment = async (formData) => {
    const token = getToken();
    return axios.post(`${API}/auto-payments`, JSON.stringify({data: formData}), {
        headers: {Authorization: `${BEARER} ${token}`, "Content-Type": "application/json"},
    }).then((response) => {
        return response.data.data.id;
    }).catch((e) => {
        console.log(e);
    })
}

export const getAutoPayment = async () => {
    const token = getToken();
    return axios.get(`${API}/get-autopayment`, {
        headers: {Authorization: `${BEARER} ${token}`},
    }).then((response) => {
        return response.data;
    }).catch((e) => {
        console.log(e);
    });
}

export const deleteAutoPayment = async (paymentId: string) => {
    const token = getToken();
    return axios.delete(`${API}/delete-autopayment/${paymentId}`, {
        headers: {Authorization: `${BEARER} ${token}`},
    }).then((response) => {
        console.log('deleteAutoPayment', response.data);
        return response.data;
    }).catch((e) => {
        console.log(e);
    });
}