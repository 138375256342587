import {action, atom} from "@reatom/core";

export const subscriptionPopup = "subscriptionPopup";

export const getIsSubscriptionPopupUsedOnce = () => {
    return localStorage.getItem(subscriptionPopup);
};

export const setIsSubscriptionPopupUsedOnce = (value) => {
    localStorage.setItem(subscriptionPopup, value);
};

export const removeIsSubscriptionPopupUsedOnce = () => {
    localStorage.removeItem(subscriptionPopup);
};

export const isLoginPopupOpenAtom = atom(false);
export const isSignInPopupOpenAtom = atom(false);
export const isSubscriptionInPopupOpenAtom = atom(false);

export const setIsLoginPopupOpen = action(async (ctx) => {
    ctx.get(() => {
        isLoginPopupOpenAtom(ctx, true);
    })
}, 'setIsLoginPopupOpen');

export const setIsLoginPopupClosed = action(async (ctx) => {
    ctx.get(() => {
        isLoginPopupOpenAtom(ctx, false);
    })
}, 'setIsLoginPopupClosed');

export const setIsSignInPopupOpen = action(async (ctx) => {
    ctx.get(() => {
        isSignInPopupOpenAtom(ctx, true);
    })
}, 'setIsSignInPopupOpen');

export const setIsSignInPopupClosed = action(async (ctx) => {
    ctx.get(() => {
        isSignInPopupOpenAtom(ctx, false);
    })
}, 'setIsSignInPopupClosed');

export const setIsSubscriptionInPopupOpen = action(async (ctx) => {
    ctx.get(() => {
        isSubscriptionInPopupOpenAtom(ctx, true);
    })
}, 'setIsSubscriptionInPopupOpen');

export const setIsSubscriptionInPopupClosed = action(async (ctx) => {
    ctx.get(() => {
        isSubscriptionInPopupOpenAtom(ctx, false);
    })
}, 'setIsSubscriptionInPopupClosed');