import "./src/styles/global.css";
import React, {useEffect} from 'react';
import {reatomContext} from '@reatom/npm-react'
import {getToken} from "./src/api/auth/token";
import {getUser} from "./src/state/userStateAtom";
import {appContext} from "./src/state/appContext";
import {getSubscription} from "./src/state/subscriptionStateAtom";
import {setUpdSubscriptionTimer} from "./src/utils/subscriptionTimer";

const fetchLoggedInUser = async () => {
    const authToken = getToken();
    if (authToken) {
        try {
            await getUser(appContext);
            await getSubscription(appContext);
            await setUpdSubscriptionTimer();
        } catch (error) {
            console.error(error);
        } finally {
        }
    }
};

export const wrapRootElement = ({element}) => {
    fetchLoggedInUser();
    return <reatomContext.Provider value={appContext}>
        {element}
    </reatomContext.Provider>;
};