import {clearIsValidSubscription, getSubscription} from "../state/subscriptionStateAtom";
import {appContext} from "../state/appContext";
import {setIsSubscriptionInPopupOpen} from "../state/popupStateAtom";

let timeoutId;

// Define your function
export async function closeSubscription() {
    console.log("Function executed at a specific time!");
    await setIsSubscriptionInPopupOpen(appContext);
    await clearIsValidSubscription(appContext);
    // Clear the timeout after execution
    clearTimeoutById();
}

export function clearTimeoutById() {
    clearTimeout(timeoutId);
}

export function setUpdSubscriptionTimer() {
    timeoutId = setInterval(async () => await getSubscription(appContext), 600000);
}
