import {AUTH_TOKEN} from "./constants";

const windowGlobal = typeof window !== 'undefined' && window

export const getToken = () => {
    return windowGlobal?.localStorage?.getItem(AUTH_TOKEN);
};

export const setToken = (token) => {
    if (token) {
        windowGlobal?.localStorage?.setItem(AUTH_TOKEN, token);
    }
};

export const removeToken = () => {
    windowGlobal?.localStorage?.removeItem(AUTH_TOKEN);
};