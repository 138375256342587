import {action, atom} from "@reatom/core";
import {userStateAtom} from "./userStateAtom";
import {getOperations} from "../api/payment/paymentStrapi";
import {
    getIsSubscriptionPopupUsedOnce,
    isSubscriptionInPopupOpenAtom,
    setIsSubscriptionPopupUsedOnce
} from "./popupStateAtom";

export type SubscriptionType = {
    id: number,
    uuid: string,
    createdAt: string,
    updatedAt: string,
    publishedAt: string,
    paymentId: string,
    expirationDate: string,
    users_permissions_user: {
        data: {
            id: number,
            attributes: {
                username: string,
                email: string,
                provider: string,
                confirmed: boolean,
                blocked: boolean,
                createdAt: string,
                updatedAt: string
            }
        }
    }
}

export const subscriptionStateAtom = atom<SubscriptionType | null>(null);
export const isValidSubscription = atom<boolean>(false);

export const getSubscription = action(async (ctx) => {
    const user = ctx.get(userStateAtom);
    if (user && Object.keys(user).length !== 0) {
        const userId = user.id;
        const data = await ctx.schedule(() =>
            getOperations(),
        )
        const subscription = data && data[0];
        if (subscription) {
            ctx.get(() => {
                subscriptionStateAtom(ctx, subscription);
            })
        }
        ctx.get(() => {
            isValidSubscription(ctx, userId && subscription);
        })
        const popupOpenedOnce = await getIsSubscriptionPopupUsedOnce();
        if (!popupOpenedOnce && !subscription) {
            ctx.get(() => {
                isSubscriptionInPopupOpenAtom(ctx, true);
            })
            setIsSubscriptionPopupUsedOnce(true);
        } else {
            ctx.get(() => {
                isSubscriptionInPopupOpenAtom(ctx, false);
            })
        }
    } else {
        ctx.get(() => {
            isValidSubscription(ctx, false);
            subscriptionStateAtom(ctx, {} as SubscriptionType);
        })
    }
}, "getSubscription");

export const setIsValidSubscription = action(async (ctx) => {
    ctx.get(() => {
        isValidSubscription(ctx, true);
    })
}, 'setIsValidSubscription');

export const clearSubscription = action(async (ctx) => {
    ctx.get(() => {
        subscriptionStateAtom(ctx, null);
    })
}, "clearSubscription")

export const clearIsValidSubscription = action(async (ctx) => {
    await clearSubscription(ctx);
    ctx.get(() => {
        isValidSubscription(ctx, false);
    })
}, "clearIsValidSubscription")