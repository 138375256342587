exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-courses-js": () => import("./../../../src/pages/courses.js" /* webpackChunkName: "component---src-pages-courses-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-oferta-js": () => import("./../../../src/pages/oferta.js" /* webpackChunkName: "component---src-pages-oferta-js" */),
  "component---src-pages-popups-login-jsx": () => import("./../../../src/pages/popups/login.jsx" /* webpackChunkName: "component---src-pages-popups-login-jsx" */),
  "component---src-pages-popups-signup-jsx": () => import("./../../../src/pages/popups/signup.jsx" /* webpackChunkName: "component---src-pages-popups-signup-jsx" */),
  "component---src-pages-popups-subscription-info-jsx": () => import("./../../../src/pages/popups/subscriptionInfo.jsx" /* webpackChunkName: "component---src-pages-popups-subscription-info-jsx" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-templates-course-template-js": () => import("./../../../src/templates/course-template.js" /* webpackChunkName: "component---src-templates-course-template-js" */),
  "component---src-templates-reset-password-tsx": () => import("./../../../src/templates/reset-password.tsx" /* webpackChunkName: "component---src-templates-reset-password-tsx" */),
  "component---src-templates-tutorial-template-js": () => import("./../../../src/templates/tutorial-template.js" /* webpackChunkName: "component---src-templates-tutorial-template-js" */),
  "component---src-templates-user-profile-tsx": () => import("./../../../src/templates/user-profile.tsx" /* webpackChunkName: "component---src-templates-user-profile-tsx" */)
}

